import React from 'react'
import {
    Box,
    Heading,
    Flex,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Link,
    Text,
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { EntitledList } from './EntitledList'

export const CompetitorReview = ({
    name,
    url,
    location,
    images,
    pros,
    cons,
    notes,
    platform,
    children,
    ...extraProps
}) => {
    let primaryImage = null
    if (images?.length) {
        const primaryImageItem = images[0]
        primaryImage = getImage(primaryImageItem?.imgPrimary)
    }

    return (
        <Flex {...extraProps} flexDir="column" gap={12} px={{ base: 0, md: 8 }} py={8}>
            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
                gap={8}
                alignItems={{ base: 'flex-start', md: 'center' }}
            >
                <Box
                    borderStyle="solid"
                    borderColor="massBayLightBlue.500"
                    borderWidth={{ base: 0, md: '1px 0 0 1px' }}
                    p={{ base: 0, md: 6 }}
                >
                    {name && (
                        <Heading as="h2" size="xl">
                            {name}
                        </Heading>
                    )}
                    {location && (
                        <Heading as="h3" size="lg" fontStyle="italic">
                            {location}
                        </Heading>
                    )}
                    {url && (
                        <Link href={url} isExternal target="_blank" rel="noopener noreferrer">
                            {url}
                        </Link>
                    )}
                    {platform && (
                        <Text mt={4}>
                            <Box as="span">Main Platform/Tech: </Box>
                            <Box as="span" fontStyle="italic">
                                {platform.join(', ')}
                            </Box>
                        </Text>
                    )}
                </Box>
                {primaryImage && (
                    <Box>
                        <GatsbyImage image={primaryImage} alt="" />
                    </Box>
                )}
            </Flex>
            <Flex flexDir="column" gap={12}>
                {(!!pros?.length || !!cons?.length) && (
                    <Flex flexDir={{ base: 'column', md: 'row' }} gap={8}>
                        {pros && <EntitledList heading="What We Like" items={pros} />}
                        {cons && <EntitledList heading="What We Don't Like" items={cons} />}
                    </Flex>
                )}
                {!!notes?.length && (
                    <Box mt={12}>
                        <EntitledList heading="Other Observations" items={notes} />
                    </Box>
                )}
                {!!images?.length && (
                    <Flex gap={4} flexWrap="wrap">
                        {images.map(
                            (image, index) =>
                                index > 0 &&
                                image?.imgThumb &&
                                image?.imgMedium && (
                                    <Box
                                        key={index}
                                        _hover={{ cursor: 'pointer', transform: 'scale(1.1)' }}
                                        transition="0.3s all"
                                    >
                                        <Popover placement="top" size="xl">
                                            <PopoverTrigger>
                                                <Box>
                                                    <GatsbyImage image={getImage(image.imgThumb)} alt="" />
                                                </Box>
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverArrow />
                                                <PopoverBody>
                                                    <GatsbyImage image={getImage(image.imgMedium)} alt="" />
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </Box>
                                )
                        )}
                    </Flex>
                )}
                {!!children?.length && <Box>{children}</Box>}
            </Flex>
        </Flex>
    )
}
