import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { PageLayout } from '../components/PageLayout'
import { Hero } from '../components/Hero'
import { CompetitorReview } from '../components/CompetitorReview'
import { PageBlock } from '../components/PageBlock'

export const Head = () => (
    <>
        <title>College Websites Survey | Orientier Proposal Hub for MassBay Community College Website Refresh</title>
        <meta name="robots" content="noindex" />
    </>
)

const hero = (
    <Hero
        heading="Surveying the Landscape of College Websites"
        subheading="A limited review of higher ed sites and their relevance to this project"
    />
)

const competitors = [
    {
        name: 'Nashville State Community College',
        location: 'Nashville, Tennessee',
        url: 'https://nscc.edu',
        imageDir: 'nashville',
        pros: [
            'Relatively modern design',
            'Manageable menus',
            'Design elements that add just enough visual interest without being distracting',
            'Selective stats and numbers display',
            'Persona-based CTAs and pathing',
            'Generally good responsive design (on various screen sizes)',
            'Program search/filter page',
        ],
        cons: [
            'Navigation/menu items could be more clearly hierarchical and/or visually prioritized',
            'No multilingual content or translation options',
            '.php URLs',
            'Some quirks with program search/filter page',
            'Some text-over-image/video legibility issues',
            'Program type icons in Program Finder are helpful but not explained until opening the Program Type filter menu',
        ],
        notes: [],
        platform: ['Omni CMS'],
    },
    {
        name: 'MassBay Community College',
        location: 'Massachusetts, USA',
        url: 'https://www.massbay.edu',
        imageDir: 'massbay',
        pros: [
            'Responsive design',
            'Attempt to use brand colors',
            'Scroll-aware sticky header',
            'Multilevel mobile nav',
            'Many images appear to be optimized without excessive quality loss',
            'Use of CDN for static image assets',
        ],
        cons: [
            'Overwhelming navigation',
            'Color and font inconsistencies between website and brand guidelines',
            'Scroll-aware sticky header height/layout',
            'No multilingual content or translation options',
            'Catalog/Acalog homepage exceeds device width on desktop',
            'Acalog course details are unnecessarily hard to digest',
            'Sidebar nav does not clearly indicate section hierarchy, and main nav does not indicate active trail/current page context',
            'Inconsistent use of whitespace, padding, margins, etc.',
            'Typography/text styles often detract from reader focus',
            'Hover state quirks',
            'Hero carousel and overlaid CTA',
        ],
        notes: ['MassBay appears to use Amazon CloudFront CDN. Discuss pros/cons and alternatives.'],
        platform: ['Drupal 7'],
    },
    {
        name: 'UMass Boston',
        location: 'Boston, Massachusetts',
        url: 'https://www.umb.edu',
        imageDir: 'umb',
        pros: [
            'Curated navigation',
            'Good use of photos, with multiple thematic image styles',
            'Good use of whitespace',
            'Relevant calls to action (CTAs) at various points on the page',
            'Simple but informative and attractive program pages',
            'Sticky header with high-value nav items (persona-based)',
            'Good handling of nav items between mobile and desktop layouts',
            'Staggered column scroll effects to increase engagement and avoid vertical whitespace discrepanceies between side-by-side content panes',
        ],
        cons: [
            'Content fade-in-on-scroll effect sometimes creates temporary doubt as to whether content exists',
            'Many images are not optimized for mobile or for certain image presentations (i.e. an unnecessarily large image is served and then scaled down quite a bit)',
            'Carousel block is not immediately intuitive if user scrolls it mostly into view without seeing the carousel nav beneath',
        ],
        notes: [
            'Site was recently redesigned by internal web team. Went live on 7/27/23 and post-launch issues are still being addressed',
            'Sticky header nav does not list prospective students as a persona, but targets them with the three main CTA buttons',
            'Catalog uses Acalog via custom subdomain (catalog.umb.edu), styled reasonably well',
        ],
        platform: ['AWS/Other'],
    },
]

const ReviewPage = ({ data }) => {
    const images = {}
    data?.allFile?.group?.forEach((group) => {
        images[group?.fieldValue] = group?.edges
    })

    return (
        <PageLayout hero={hero} pageSlug="college-site-review">
            <PageBlock variant="minimal">
                <Flex flexDir="column" alignItems="space-between" gap={8}>
                    {competitors &&
                        competitors.map((competitor, index) => {
                            const competitorImages =
                                typeof images[`competitors/${competitor?.imageDir}`] !== 'undefined'
                                    ? images[`competitors/${competitor?.imageDir}`]
                                    : []
                            const { imageDir, ...extraProps } = competitor
                            return (
                                <>
                                    <CompetitorReview key={index} images={competitorImages} {...extraProps} />
                                    {index < competitors.length - 1 && (
                                        <Text
                                            textAlign="center"
                                            color="massBayLightBlue.500"
                                            fontSize="9xl"
                                            lineHeight="none"
                                            fontFamily="serif"
                                        >
                                            ~
                                        </Text>
                                    )}
                                </>
                            )
                        })}
                </Flex>
            </PageBlock>
        </PageLayout>
    )
}

export default ReviewPage

export const pageQuery = graphql`
    query {
        allFile(
            filter: { relativeDirectory: { regex: "/competitors/.+/" } }
            sort: { fields: [relativeDirectory, name], order: ASC }
        ) {
            group(field: relativeDirectory) {
                field
                fieldValue
                totalCount
                edges {
                    imgThumb: node {
                        childImageSharp {
                            gatsbyImageData(width: 100, height: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                    imgPrimary: node {
                        childImageSharp {
                            gatsbyImageData(width: 480, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                    imgMedium: node {
                        childImageSharp {
                            gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                    }
                }
            }
        }
    }
`
